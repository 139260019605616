import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";

const IndexPage = ({ data }) => (
  <Layout navColour="black">
    <section id="hero" className="hero" style={{ background: "#d61f99" }}>
      <div className="hero_content"
        style={{
          maxWidth: "720px",
          marginLeft: "auto",
          marginRight: "auto"
        }}>
        <header className="hero_text">
          <h1 className="uppercase text-display-xl text-bold" style={{ color: "#fff814" }}>
            {data.datoCmsMainHeader.heading}
          </h1>
        </header>
        <div dangerouslySetInnerHTML={{
          __html: data.datoCmsMainHeader.textNode.childMarkdownRemark.html
        }} style={{
          fontSize: "1.225rem",
          color: "#fff"
        }} />
      </div>
    </section>

    <section className="hero" style={{ background: "#FFFCF5" }}>
      <div className="hero_content"
        style={{
          maxWidth: "720px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div dangerouslySetInnerHTML={{
          __html: data.datoCmsSupportingText.textNode.childMarkdownRemark.html
        }} style={{ fontSize: "1rem" }} />
        <Link to={data.datoCmsSupportingText.buttonUrl} className="case_study_link">
          <button className="button" style={{
            padding: "8px 0px",
            border: "0px"
          }}>
            {data.datoCmsSupportingText.button}
          </button>
        </Link>
      </div>
    </section>


    <section className="article-listing" style={{
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      maxWidth: "940px",
      marginLeft: "auto",
      marginRight: "auto"
    }}>
      {
        data.allDatoCmsCaseStudy.edges.map(({ node: work }) => (
          <article key={work.id} style={{
            // width: "50%",
            padding: "15px"
          }}>
            <Link to={`/case-study/${work.slug}`}>
              <div style={{
                display: "block",
                borderRadius: "8px",
                // maxWidth: "460px",
                // maxHeight: "300px",
                overflow: "hidden",
              }}>
                <picture>
                  <img src={work.featured.url} alt={work.featured.alt} style={{ borderRadius: "12px" }} />
                </picture>
              </div>
            </Link>
            <Link to={`/case-study/${work.slug}`} className="article-link text-bold">
              <h2 style={{ fontSize: "24px", marginTop: "1em" }}>{work.title} <span className="company">{work.company}</span></h2>
            </Link>
            {/*
            <div dangerouslySetInnerHTML={{
              __html: work.excerptNode.childMarkdownRemark.excerpt
            }} />
            <Link to={`/case-study/${work.slug}`}>Read case study</Link> */}

          </article>
        ))
      }
    </section>

    <section className="article-listing" style={{
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      maxWidth: "940px",
      marginLeft: "auto",
      marginRight: "auto"
    }}>
      {
        data.allDatoCmsComingSoon.edges.map(({ node: soon }) => (
          <article key={soon.id} style={{
            // width: "50%",
            padding: "15px"
          }}>
            <div style={{
              display: "block",
              borderRadius: "8px",
              // maxWidth: "460px",
              // maxHeight: "300px",
              overflow: "hidden",
            }}>
              <picture>
                <img src={soon.featuredImage.url} alt={soon.featuredImage.alt} style={{ borderRadius: "12px" }} />
              </picture>
            </div>
            <Link to="/" className="article-link text-bold">
              <h2 style={{ fontSize: "24px", marginTop: "1em" }}>{soon.title} <span className="company">{soon.company}</span></h2>
            </Link>
            {/* <div dangerouslySetInnerHTML={{
              __html: soon.excerptNode.childMarkdownRemark.excerpt
            }} /> */}
            <em>Coming soon</em>

          </article>
        ))
      }
    </section>

    <hr style={{ background: "#F2F2F2", margin: "40px 0" }} />

    <section style={{
      display: "flex",
      width: "100%",
      maxWidth: "940px",
      padding: "15px",
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: "40px"
    }}>
      <div className="writing text-bold" style={{ maxWidth: "140px" }}>
        Writing
      </div>
      <div className="writing-right">
        {
          data.allDatoCmsMediumPost.edges.map(({ node: medium }) => (
            <article key={medium.id} className="writing-container">
              <a href={medium.url} target="_blank" rel="noopener noreferrer" className="text-bold" style={{ color: "000", fontSize: "24px" }}>
                {medium.headline}
              </a>
              <footer style={{ marginTop: "16px" }}>
                {medium.datePublished} • {medium.readTime}
              </footer>
            </article>
          ))
        }
      </div>

    </section>




    {/* <div className="content">
      <div className="container">
        <section className="row">
          <h3 className="text-section uppercase text-small text-bold text-sans-serif">
            Case studies
            </h3>


          <article key={data.singlePost.id} className="case_study card">
            <div className="card_preview">
              <img src="https://www.datocms-assets.com/22518/1584394920-smoothrecruitfeature-compressor.png" alt="Smooth Recruit UI UX Product Design" />
            </div>
            <div className="card_content">
              <Link to={`/case-study/${data.singlePost.slug}`}>
                <h3 className="text-display-l">{data.singlePost.title}</h3>
              </Link>
              <p>
                <div dangerouslySetInnerHTML={{
                  __html: data.singlePost.excerptNode.childMarkdownRemark.excerpt
                }} />
              </p>
              <p>
                <Link
                  className="case_study_link"
                  to={`/case-study/${data.singlePost.slug}`}
                >
                  Read case study
                  </Link>
              </p>
            </div>
          </article>


        </section>
        <section className="row" style={{ marginTop: "70px" }}>
          <article className="case_study card" style={{ flexDirection: "row-reverse", background: "#fff", borderColor: "#fff" }}>
            <div className="card_preview" style={{ position: "relative" }}>
              <div className="iphone" style={{ padding: "0.5rem", background: "#f9f9f9", borderRadius: "24px", zIndex: "1" }}>
                <video width="270" style={{ width: "270px", borderRadius: "24px" }} autoplay="true" loop="true" muted="true" plays="true" inline="true">
                  <source src="https://www.datocms-assets.com/22518/1590161918-mane-hook-up-2.mp4" type="video/mp4" />
                </video>
              </div>
              <div style={{ position: "absolute", right: "0", top: "10%", boxShadow: "0 0 16px rgba(0,0,0,.1)", maxWidth: "700px", width: "100%", minWidth: "500px" }}>
                <img src="https://www.datocms-assets.com/22518/1590149732-homesmall2-compressor.png" alt="Mane Hook-up" />
              </div>
            </div>
            <div className="card_content" style={{ alignSelf: "center" }}>
              <Link to="/"
                onClick={e => {
                  e.preventDefault();
                }}>
                <h2 className="text-display-l">Mane Hook-Up</h2>
              </Link>
              <p>
                Mane Hook-Up was launched in September 2019 with the aim of helping people find local hairdressers and stylists who specialise in managing afro hair.
              </p>
              <p>
                <Link
                  className="case_study_link text-lightgrey"
                  to="/"
                  onClick={e => {
                    e.preventDefault();
                  }}
                >
                  (Case study coming soon)
                  </Link>
              </p>
            </div>
          </article>


        </section> */}
    {/* <section style={{ maxWidth: "700px", marginTop: "3rem" }}> */}
    {/* Case studies under development */}
    {/* <article key="2" className="case_study coming_soon">
            <Link to="/"
              onClick={e => {
                e.preventDefault();
              }}>
              <h2 className="text-display-l">Gamification and forex trading case study - FaithFX</h2>
            </Link>
            <div>
              <p>FaithFX is a platform that provides educational foreign exchange trading material. A new way of teaching trading to students was the next…</p>
            </div>
            <p>
              <Link
                className="case_study_link text-lightgrey"
                to="/"
                onClick={e => {
                  e.preventDefault();
                }}
              >
                (Case study coming soon)
                  </Link>
            </p>
          </article>
          <article key="3" className="case_study coming_soon">
            <Link to="/"
              onClick={e => {
                e.preventDefault();
              }}>
              <h2 className="text-display-l">The beginning of a design system</h2>
            </Link>
            <div>
              <p>I was beginning a new project at CharityJob. The task was to create a product that makes recruitment better for charities.</p>
            </div>
            <p>
              <Link
                className="case_study_link text-lightgrey"
                to="/"
                onClick={e => {
                  e.preventDefault();
                }}
              >
                (Case study coming soon)
                  </Link>
            </p>
          </article> */}




    {/* Temporarily unavailable until further development */}
    {/* {data.allDatoCmsCaseStudy.edges.map(({ node: work }) => (
            <article key={work.id} className="case_study">
              <Link to={`/case-study/${work.slug}`}>
                <h2 className="text-display-l">{work.title}</h2>
              </Link>
              <div>
                <div dangerouslySetInnerHTML={{
                  __html: work.excerptNode.childMarkdownRemark.excerpt
                }} />
              </div>
              <p>
                <Link
                  className="case_study_link"
                  to={`/case-study/${work.slug}`}
                >
                  Read case study
                  </Link>
              </p>
            </article>
          ))} */}
    {/* </section>
      </div>

    </div > */}
  </Layout >
);
export default IndexPage;

export const query = graphql`
  query IndexQuery {
    allDatoCmsCaseStudy(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          title
          company
          slug
          tags {
            id
            tagName
          }
          excerptNode {
            childMarkdownRemark {
              excerpt(format: PLAIN)
            }
          }
          featured {
            url
            alt
            fluid(maxWidth: 460, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
          coverImage {
            url
            alt
            fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
    datoCmsMainHeader(ref: {eq: "home-header"}) {
      heading
      textNode {
        childMarkdownRemark {
          html
        }
      }
    }
    datoCmsSupportingText(ref: {eq: "home"}) {
      textNode {
        childMarkdownRemark {
          html
        }
      }
      button
      buttonUrl
    }
    allDatoCmsMediumPost {
      edges {
        node {
          headline
          url
          readTime
          datePublished
        }
      }
    }
    singlePost: datoCmsCaseStudy(id: {eq: "DatoCmsCaseStudy-2934253-en"}) {
      id
      title
      excerptNode {
        childMarkdownRemark {
          excerpt(format: PLAIN)
        }
      }
      slug
    }
    datoCmsTopSectionIntro(sectionName: { eq: "intro" }) {
      headline
      line1
      inline
      line2
      snippet
    }
    allDatoCmsComingSoon {
      edges {
        node {
          title
          company
          featuredImage {
            alt
            url
          }
          excerptNode {
            childMarkdownRemark {
              excerpt(format: PLAIN)
            }
          }
        }
      }
    }
  }
`;
